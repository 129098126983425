<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input v-model="searchParams.theme" size='small' style="width:150px;margin:5px;" placeholder='活动主题'></a-input>
                <a-select  allowClear v-model="searchParams.shelfStatus" size='small' style="width:150px;margin:5px;" placeholder='上架状态'>
                    <a-select-option :value='0'>下架</a-select-option>
                    <a-select-option :value='1'>上架</a-select-option>
                </a-select>
                <a-button  @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>
            <a-button size='small' style="margin:5px;" @click="openModal({})">创建活动</a-button>

        </div>
        <div class="table-content" style="padding-top:20px;">
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>record.id||n">
                <div slot="images" slot-scope="row">
                    <div style='max-width:100px;max-height:100px;display:flex;justify-content:center;align-items:center;'>
                        <img @click="previewImg(row)" style='max-width:100px;max-height:100px;' :src='row.coverUrl'/>
                    </div>
                </div>
                 <div slot="setup" slot-scope="row">
                    <a @click="openModal(row,1)" style='margin-right:5px'>编辑</a>
                    <a @click="racking(row.id,row.shelfStatus === 0 ? 1 : 0)" style='margin-right:5px'>{{row.shelfStatus === 0 ? '上架' : '下架'}}</a>
                    <a @click="openPriceModal(row.id)" style='margin-right:5px'>查看出价记录</a>
                     <a-popconfirm
                        title="确认删除么?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row.id)"
                    >
                    <a style='margin-left:5px'>删除</a>
                   </a-popconfirm>

                </div>

            </a-table>
        </div>
        <a-modal :width='800' v-model="visible" title="录入盲拍活动" ok-text="确认" cancel-text="取消" @cancel='resetForms' @ok="hideModal">
            <a-form-model
                ref="ruleForm"
                :model="forms"
                :rules="rules"
            >
                <a-form-model-item label="用户" prop="userId" >
                    <a-select size='small' v-model="forms.userId" :filter-option="untils.filterOption" showSearch placeholder='请选择'>
                        <a-select-option v-for="item of users" :key="item.id" :value='item.id'>
                            <div style="display:flex;align-items:center;">
                                <img style="max-width:20px;max-height:20px;border-radius:50%;margin-right:10px;" :src="item.userAvatar" alt="">
                                <span :text='item.userName'>{{item.userName}}</span>
                            </div>
                            <!-- {{item.userName}} -->
                        </a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="图片" >
                    <a-upload
                    :key="imgKey"
                    :action="ip+'/file/upload'"
                    list-type="picture-card"
                    :file-list="fileList"
                    @preview="handlePreview"
                    @change="handleChange"
                    >
                    <div >
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传图片</div>
                    </div>
                    </a-upload>
                </a-form-model-item>

                 <a-form-model-item label="活动期数" prop="theme" >
                    <a-textarea auto-size sizeq='small' placeholder='请输入' v-model="forms.theme" ></a-textarea>
                </a-form-model-item>

                 <a-form-model-item label="活动标题" prop="tipNotice" >
                    <a-textarea auto-size size='small' placeholder='请输入' v-model="forms.tipNotice"></a-textarea>
                </a-form-model-item>

                 <a-form-model-item label="活动副标题" prop="info" >
                    <a-textarea auto-size size='small' placeholder='请输入' v-model="forms.info"></a-textarea>
                </a-form-model-item>

                 <a-form-model-item label="商品名称" prop="title" >
                    <a-textarea auto-size size='small' placeholder='请输入' v-model="forms.title"></a-textarea>
                </a-form-model-item>


                <a-form-model-item label="保证金临界值" prop="conditionNearMoney" >
                    <a-input size='small' placeholder='请输入' v-model="forms.conditionNearMoney" :disabled="forms.shelfStatus==1"></a-input>
                </a-form-model-item>

                 <a-form-model-item label="规则人数" prop="conditionMaxNum" >
                    <a-input size='small' placeholder='请输入' v-model="forms.conditionMaxNum" :disabled="forms.shelfStatus==1"></a-input>
                </a-form-model-item>

                 <a-form-model-item label="保证金" prop="conditionDepositMoney" >
                    <a-input size='small' placeholder='请输入' v-model="forms.conditionDepositMoney" :disabled="forms.shelfStatus==1"></a-input>
                </a-form-model-item>


                <a-form-model-item label="规则路径" prop="ruleUrl" >
                    <a-textarea auto-size size='small' placeholder='请输入' v-model="forms.ruleUrl"></a-textarea>
                </a-form-model-item>

                <div>
                <a-form-model-item label="开始时间" prop="startTime"  >

                    <a-date-picker
                            :disabled="forms.shelfStatus==1"
                      v-model="forms.startTime"
                      :disabled-date="disabledStartDate"
                      show-time
                      format="YYYY-MM-DD HH:mm:ss"
                      placeholder="开始时间"
                      @openChange="handleStartOpenChange"
                    />
                </a-form-model-item>

                <a-form-model-item label="结束时间" prop="endTime" >
                    <a-date-picker
                            :disabled="forms.shelfStatus==1"
                      v-model="forms.endTime"
                      :disabled-date="disabledEndDate"
                      show-time
                      format="YYYY-MM-DD HH:mm:ss"
                      placeholder="结束时间"
                      :open="endOpen"
                      @openChange="handleEndOpenChange"
                    />
                </a-form-model-item>
              </div>


            </a-form-model>
        </a-modal>


         <a-modal centered :width='800' v-model="visiblePrice" title="出价记录" @cancel='cancelModal' :footer="null" >
           <div class="take-box">
               <a-table  @change="changePageModal" :pagination='paginationModal' :loading='loadingModal' :columns='takeColumns' :data-source='messages' :rowKey="(record,n)=>n">
                <div slot="itemUserNameSlot" slot-scope="row">
                  <div class="color-black">{{  row.userName }}</div>
                  <div class="color-green">【{{  row.userId }}】</div>
                </div>
               </a-table>
           </div>
        </a-modal>
    </div>
</template>

<script>
import {mapState} from "vuex";
export default {
    data(){
        return {
            columns:[
                {title:'图片',scopedSlots:{customRender:'images'}},
                {title:'发布者',dataIndex:'userName'},
                {title:'活动主题',dataIndex:'theme'},
                {title:'商品名称',dataIndex:'title'},
                {title:'活动告示',dataIndex:'tipNotice'},
                {title:'活动信息',dataIndex:'info'},
                {title:'开始时间',dataIndex:'startTime'},
                {title:'结束时间',dataIndex:'endTime'},
                {title:'成交价格',dataIndex:'finalPrice'},
                {title:'参与人数',dataIndex:'joinNum'},
                {title:'上下架状态',
                    customRender:(text, row, index) => {
                            const {shelfStatus} = row

                            return shelfStatus == 1 ? <span style='color:green'>上架</span> : <span style='color:red'>下架</span>
                        }
                },

                {title:'管理操作',align:'center',scopedSlots: { customRender: 'setup' }},
            ],
            data:[],
            messages:[],
            forms:[

            ],
            rules: {
                userId:[{ required: true, message: '请选择用户', trigger: 'change' }],
                startTime:[{ required: true, message: '不能为空', trigger: 'change' }],
                endTime:[{ required: true, message: '不能为空', trigger: 'change' }],
                conditionNearMoney:[{ required: true, message: '不能为空', trigger: 'blur' }],
                title:[{ required: true, message: '不能为空', trigger: 'blur' }],
                conditionDepositMoney:[{ required: true, message: '不能为空', trigger: 'blur' }],
                ruleUrl:[{ required: true, message: '不能为空', trigger: 'blur' }],
                conditionMaxNum:[{ required: true, message: '不能为空', trigger: 'blur' }],
            },
            searchParams:{
                pageSize:10,
                pageNum:1,
                theme:"",
            },
            fileList:[],
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            imgKey:'',
            users:[],
            visible:false,
            endOpen:false,
            paginationModal:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loadingModal:false,
            takeColumns:[
                {title:'昵称', scopedSlots: { customRender: 'itemUserNameSlot' },},
                {title:'出价',width:'200',dataIndex: 'bidPrice'},
                {title:'时间',width:'200',align:'center',dataIndex:'createTime'},
            ],
            visiblePrice:false,
            modalParams:{},
            IO:0

            
        }
    },

    mounted(){
        addEventListener('paste',(e)=>{
            if(this.visible){
                this.untils.pasteImg(e,1,this.paseImgs)
            }
        })

    },
    computed:{
        ...mapState(['ip'])
    },
    created(){
        this.getData(this.searchParams)
        this.getUsers()

    },

    methods:{
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/blindActivity/list',{params:params})
            this.loading = false
            const {records,total} = res.data
            this.data = records
            this.pagination.total = total 
        },
        openPriceModal(id){
            this.visiblePrice=true
            Object.assign(this.modalParams,{pageNum:1,pageSize:10,activityId:id})
            this.getTakeMessages(this.modalParams)

        },
        async getTakeMessages(params){
            this.loadingModal = true
            const result = await this.axios('/dq_admin/blindUserBid/listByActivityId',{params:params})
            this.loadingModal = false
            if(result.status == 200){
                const {records=[],total} = result.data
                this.messages = records
                this.paginationModal.total = total
            }
        },
        openModal(row,type){
            this.visible = true
            this.imgKey = Math.random()
            this.fileList = []
            if(type){
                this.IO = 1
                this.forms = JSON.parse(JSON.stringify(row))
                this.currency_big_list = [{coinKindName:row.coinKindName,id:1,sid:row.coinSid}]
                const {detailImage} = row || {detailImage:[]}
                if(detailImage&&detailImage.length>0){
                    this.fileList = detailImage.map((items,i)=>{
                        const obj = {
                            uid:i,name: 'image',status: 'done',url:items,
                        }
                        return obj
                    })

                }

            }else{
                this.IO = 0
                this.forms = {
                    // tags:[],
                    // detailImage:[],
                }
            }
        },
        cancelModal(){
            // this.modalParams = {}
            this.messages = []
        },

        previewImg(row){
            if(row.detailImage&&row.detailImage.length>0){
                this.$previewImg({
                    list:row.detailImage.reduce((pre,cur)=>{
                        const obj = {img_url:cur}
                        pre = pre.concat(obj)
                        return pre
                    },[]),

                    baseImgField:'img_url',
                    showMute:false,
                })
            }
        },
        changePageModal(page){
            const {current} = page
            this.paginationModal.current = current
            this.modalParams.pageNum = current
            this.getTakeMessages(this.modalParams)
        },
        search(){
            this.searchParams.pageNum = 1
            this.pagination.current = 1
            this.getData(this.searchParams)
        },
        async getUsers(){
            const res = await this.axios('/dq_admin/user/list')
            const {data} = res
            this.users = data
        },
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.searchParams.pageNum = current
            this.searchParams.pageSize = pageSize
            this.getData(this.searchParams)
        },

        async racking(row,type){
            const res = await this.axios.post('/dq_admin/blindActivity/racking',{id:row,status:type})
            if(res.status == 200){
                this.$message.success(res.message)
                this.getData(this.params)
            }
        },

         async remove(row){
            const res = await this.axios.post('/dq_admin/blindActivity/del',{id:row})
            if(res.status == 200){
                this.$message.success(res.message)
                this.getData(this.params)
            }
        },
        resetForms(){
            this.forms = {}
            this.$refs.ruleForm.resetFields();
        },
        hideModal(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        submit(forms){
            if (typeof forms.startTime =="object"){
                forms.startTime = forms.startTime.format('YYYY-MM-DD HH:mm:ss')
                forms.endTime = forms.endTime.format('YYYY-MM-DD HH:mm:ss')
            }
            const postData = JSON.parse(JSON.stringify(forms))
            const images = this.fileList.map(row=>row.url)
            postData.detailImage = images
            // 转换time格式
            const post_url = this.IO ? '/dq_admin/blindActivity/edit' : '/dq_admin/blindActivity/add'
            postData.coverUrl = postData.detailImage[0]
            this.axios.post(post_url,{...postData}).then(res=>{
                if(res.status == '200'){
                    this.$message.success(res.message)
                    this.visible = false
                    this.getData(this.params)
                }
            })
        },

        // 图片
         async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange({fileList}){
            fileList.map((row)=>{
                try{
                    if(row.response.data){
                        row.url = row.response.data
                    }
                }catch(e){

                }
            })
            this.fileList = fileList
        },
        paseImgs(type,data,row){
            const len = this.fileList.length+1
            const obj = {uid:len,name: 'image',status: 'done',url:data,}
            this.fileList.push(obj)
        },

        // 时间
        disabledStartDate(startTime) {
          const endTime = this.forms.endTime;
          if (!startTime || !endTime) {
            return false;
          }
          return startTime.valueOf() > endTime.valueOf();
        },
        disabledEndDate(endTime) {
          const startTime = this.forms.startTime;
          if (!endTime || !startTime) {
            return false;
          }
          return startTime.valueOf() >= endTime.valueOf();
        },
        handleStartOpenChange(open) {
          if (!open) {
            this.endOpen = true;
          }
        },
        handleEndOpenChange(open) {
          this.endOpen = open;
        },


    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>